import has from 'lodash/has';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isNaN from 'lodash/isNaN';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import mapValues from 'lodash/mapValues';
import {
  variablesById,
  variablesByScope,
} from '../constants/Variables';
import {
  VARIABLE_TYPE__CUSTOM,
  VARIABLE_TYPE__NUMBER,
  VARIABLE_TYPE__STRING,
  VARIABLE_TYPE__BOOLEAN,
  VARIABLE_TYPE__DATE,
  VARIABLE_TYPE__TIME,
  VARIABLE_TYPE__DATE_TIME,
  VARIABLE_TYPE__VALUE_SET,
  VARIABLE_TYPE__LOOKUP_TABLE,
} from '../constants';
import checkSchema, {
  getErrorMessage,
} from './checkSchema';
import {
  expand,
  toBindingsArray,
} from './formValues';

const isInScope = (variable, scopeName) => {
  if (!variable) {
    return false;
  }
  if (!scopeName) {
    return true;
  }
  return variable.scopeName === scopeName;
};

const constant = x => () => x;

export const createGetVariable = (variable, scopeName) => {
  if (scopeName && variable.scopeName !== scopeName) {
    return constant(undefined);
  }
  if (variable.nativeKey) {
    return value => get(value, variable.nativeKey);
  }
  return value => value && value.variables && value.variables[variable._id];
};

export const createGetScopeVariables = (scopeName) => {
  const getters = mapValues(variablesByScope[scopeName], variable => createGetVariable(variable, scopeName));
  return value => omitBy(
    mapValues(getters, getter => getter(value)),
    isUndefined,
  );
};

export const forEachVariableInScope = (variables, scope, action) => {
  forEach(variables, (value, variableId) => {
    const variable = variablesById[variableId];
    if (isInScope(variable, scope)) {
      action(value, variableId, variable);
    }
  });
};

export const pickVariablesInScope = (variables, scope) => {
  const variablesInScope = {};
  forEachVariableInScope(variables, scope, (value, variableId) => {
    variablesInScope[variableId] = value;
  });
  return variablesInScope;
};

export const getPropertiesFromVariables = (variables, scope) => {
  const errors = [];
  const properties = {
    variables: {},
  };
  forEachVariableInScope(variables, scope, (value, variableId, variable) => {
    if (isNil(value) && !variable.required) {
      if (variable.nativeKey) {
        return;
      }
    }
    if (has(variable, 'jsonSchema')) {
      const error = checkSchema(variable.jsonSchema, value);
      if (error) {
        errors.push({
          message: getErrorMessage(error),
          variableId,
        });
        return;
      }
    }
    if (variable.nativeKey) {
      properties[variable.nativeKey] = value;
    } else {
      properties.variables[variable._id] = value;
    }
  });
  if (isEmpty(properties.variables)) {
    delete properties.variables;
  }
  return omitBy(
    {
      errors,
      properties,
    },
    isEmpty,
  );
};

export const getModifiersFromVariables = (variables, scope) => {
  const modifier = {
    $set: {},
    $unset: {},
  };
  const errors = [];
  forEachVariableInScope(variables, scope, (value, variableId, variable) => {
    if (variable.immutable) {
      return;
    }
    if (isNil(value) && !variable.required) {
      if (variable.nativeKey) {
        modifier.$unset[variable.nativeKey] = 1;
      } else {
        modifier.$unset[`variables.${variableId}`] = 1;
      }
      return;
    }
    if (has(variable, 'jsonSchema')) {
      const error = checkSchema(variable.jsonSchema, value);
      if (error) {
        errors.push({
          message: getErrorMessage(error),
          variableId,
        });
        return;
      }
    }
    if (variable.nativeKey) {
      modifier.$set[variable.nativeKey] = value;
    } else {
      modifier.$set[`variables.${variable._id}`] = value;
    }
  });
  return omitBy(
    {
      errors,
      modifier: omitBy(modifier, isEmpty),
    },
    isEmpty,
  );
};

export const parseJsonOrNull = (str) => {
  try {
    return JSON.parse(str);
  } catch (err) {
    return null;
  }
};

export const parseVariableLiteral = (valueType, valueExpr) => {
  // TODO: Maybe add schema validation here?
  // TODO: Optimize it, i.e. make sure variable is only parsed once.
  // TODO: We should probably parse date to "Date"?
  switch (valueType) {
    case VARIABLE_TYPE__NUMBER: {
      const valueNumber = +valueExpr;
      if (!isNaN(valueNumber)) {
        return valueNumber;
      }
      return null;
    }
    case VARIABLE_TYPE__CUSTOM:
    case VARIABLE_TYPE__BOOLEAN:
    case VARIABLE_TYPE__VALUE_SET:
    case VARIABLE_TYPE__LOOKUP_TABLE:
      return parseJsonOrNull(valueExpr);
    case VARIABLE_TYPE__DATE:
    case VARIABLE_TYPE__TIME:
    case VARIABLE_TYPE__DATE_TIME:
    case VARIABLE_TYPE__STRING:
      return valueExpr;
    default:
      return null;
  }
};

export const toArrayRepresentation = (variables) => {
  const expanded = {};
  forEach(variables, (value, id) => {
    if (!isNil(value)) {
      expanded[id] = expand(value);
    }
  });
  return toBindingsArray(expanded);
};
