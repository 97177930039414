import React from 'react';
import PropTypes from 'prop-types';
import Cluster from '../../common/components/primitives/Cluster';
import Settings from '../../components/Settings';
import Logo from '../../components/Logo';
import {
  getBranding,
} from '../../utils/branding';

const branding = getBranding();

const Header = ({
  loading,
}) => (
  <Cluster
    align="center"
    overflow="initial"
    space={0}
  >
    <Logo branding={branding} />
    <Settings
      loading={loading}
      onTop
    />
  </Cluster>
);

Header.propTypes = {
  loading: PropTypes.bool,
};

Header.defaultProps = {
  loading: false,
};

export default Header;
