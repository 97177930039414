import map from 'lodash/map';
import Formula from '../Formula';
import {
  FORMULA_TYPE__REDUCE,
  FORMULA_OPERATOR__SUM,
  FORMULA_OPERATOR__PROD,
  FORMULA_OPERATOR__OR,
  FORMULA_OPERATOR__AND,
  FORMULA_OPERATOR__ANY,
  FORMULA_OPERATOR__ALL,
  FORMULA_OPERATOR__WHITESPACE,
  FORMULA_OPERATOR__CONCAT,
  FORMULA_OPERATOR__MIN,
  FORMULA_OPERATOR__MAX,
} from '../../../constants';
import Schema from '../../../utils/Schema';
import operators from '../operators';

const identity = x => x;

const settingsSchema = new Schema(
  {
    formulas: [
      new Schema({
        formula: {
          type: Object,
          additionalProperties: true,
        },
      }),
    ],
  },
  {
    additionalProperties: true,
  },
);

// eslint-disable-next-line import/prefer-default-export
export class FormulaReduce extends Formula {
  validate() {
    if (!this.settings) {
      return this.constructor.NotConfigured;
    }
    if (settingsSchema.getErrors(this.settings)) {
      return this.constructor.NotConfigured;
    }
    return undefined;
  }

  evaluate(scope) {
    const op = operators[this.settings.operator] || identity;
    const values = [];
    for (let i = 0; i < this.settings.formulas.length; i += 1) {
      const {
        formula,
      } = this.settings.formulas[i];
      const {
        value,
        error,
      } = Formula.create(formula).evaluate(scope);
      if (error) {
        switch (this.settings.operator) {
          case FORMULA_OPERATOR__OR:
            if (i === this.settings.formulas.length - 1) {
              return {
                error,
              };
            }
            break;
          case FORMULA_OPERATOR__ALL:
            return {
              value: false,
            };
          case FORMULA_OPERATOR__ANY:
            values.push(false);
            break;
          case FORMULA_OPERATOR__SUM:
          case FORMULA_OPERATOR__PROD:
          case FORMULA_OPERATOR__WHITESPACE:
          case FORMULA_OPERATOR__CONCAT:
            break;
          default:
            return {
              error,
            };
        }
      } else {
        switch (this.settings.operator) {
          case FORMULA_OPERATOR__SUM:
          case FORMULA_OPERATOR__PROD: {
            const result = scope.evaluateAsNumber(value);
            if (!result.error) {
              values.push(result.value);
            }
            break;
          }
          case FORMULA_OPERATOR__AND: {
            if (!value) {
              return {
                value,
              };
            }
            values.push(value);
            break;
          }
          case FORMULA_OPERATOR__OR:
            if (value) {
              return {
                value,
              };
            }
            values.push(value);
            break;
          case FORMULA_OPERATOR__ALL: {
            if (!value) {
              return {
                value: false,
              };
            }
            break;
          }
          case FORMULA_OPERATOR__ANY: {
            if (value) {
              return {
                value: true,
              };
            }
            break;
          }
          default:
            values.push(value);
        }
      }
    }

    if (values.length === 0) {
      switch (this.settings.operator) {
        case FORMULA_OPERATOR__ALL:
          return {
            value: true,
          };
        case FORMULA_OPERATOR__ANY:
          return {
            value: false,
          };
        case FORMULA_OPERATOR__SUM:
          return {
            value: 0,
          };
        case FORMULA_OPERATOR__PROD:
          return {
            value: 1,
          };
        case FORMULA_OPERATOR__WHITESPACE:
        case FORMULA_OPERATOR__CONCAT:
          return {
            value: '',
          };
        default:
          return {
            error: this.constructor.NoData,
          };
      }
    }
    return {
      value: values.reduce(op),
    };
  }

  compile(questionsHierarchy) {
    const compiled = {
      ...this,
      settings: {
        operator: this.settings.operator,
        formulas: [],
      },
    };
    if (this.settings && this.settings.formulas) {
      compiled.settings.formulas = this.settings.formulas.map(
        ({
          formula,
        }) => ({
          formula: Formula.create(formula).compile(questionsHierarchy),
        }),
      );
    } else {
      compiled.settings.formulas = [];
    }
    return compiled;
  }

  toMongoExpression() {
    return {
      $reduce: {
        input: this.settings.formulas.map(({
          formula,
        }) => formula.toMongoExpression()),
        in: (() => {
          switch (this.settings.operator) {
            case FORMULA_OPERATOR__SUM:
              return {
                $add: [
                  '$$value',
                  '$$this',
                ],
              };
            case FORMULA_OPERATOR__PROD:
              return {
                $multiply: [
                  '$$value',
                  '$$this',
                ],
              };
            case FORMULA_OPERATOR__OR:
              return {
                $cond: [
                  '$$value',
                  '$$value',
                  '$$this',
                ],
              };
            case FORMULA_OPERATOR__AND:
              return {
                $cond: [
                  '$$value',
                  '$$this',
                  '$$value',
                ],
              };
            case FORMULA_OPERATOR__CONCAT:
              return {
                $concat: [
                  '$$value',
                  '$$this',
                ],
              };
            case FORMULA_OPERATOR__WHITESPACE:
              return {
                $concat: [
                  '$$value',
                  ' ',
                  '$$this',
                ],
              };
            case FORMULA_OPERATOR__MIN:
              return {
                $min: [
                  '$$value',
                  '$$this',
                ],
              };
            case FORMULA_OPERATOR__MAX:
              return {
                $max: [
                  '$$value',
                  '$$this',
                ],
              };
            default:
              return '$$this';
          }
        })(),
      },
    };
  }

  static createMapSettings(mapQuestionId) {
    return (value, key) => {
      switch (key) {
        case 'formulas':
          return map(value, fields => ({
            ...fields,
            formula: Formula.create(fields.formula)
              .remap(mapQuestionId)
              .toRawFormula(),
          }));
        default:
          return value;
      }
    };
  }
}

Formula.types[FORMULA_TYPE__REDUCE] = FormulaReduce;
