import isEmpty from 'lodash/isEmpty';
import Questionnaire from '../../models/Questionnaire';
import SelectorsHub from './SelectorsHub';
import {
  ACTION_FORCE_AUTOSAVE,
  ACTION_SYNC_FORM_VALUES,
  ACTION_VALIDATE_QUESTIONNAIRE,
  replaceFormValues,
  replaceFormErrors,
  ACTION_SET_VALUE,
  ACTION_ENTER_FORM_VALUES,
  ACTION_INSERT_ELEMENT,
  ACTION_REMOVE_ELEMENT,
} from './actions';
import DelayedTask from './DelayedTask';

function createQuestionnaireMiddleware({
  autosaveDelay = 1000,
  handleAutosave,
  getInitialValues,
} = {}) {
  return (store) => {
    /**
     * Extract current form values. Please note that all behaviors are taken into account here,
     * which means a hidden question will not have any answer even if it was originally present.
     * @param {Object} options
     * @private
     */
    const getNewFormValuesAndDynamicProperties = (options) => {
      const state = store.getState();
      if (options.questionnaire) {
        const context = new SelectorsHub(options);
        return {
          formValues: context.select.formValues()(state),
          dynamicProperties: context.select.dynamicProperties()(state),
        };
      }
      return {
        formValues: {},
        dynamicProperties: {},
      };
    };

    const getCurrentFormErrors = (options) => {
      const state = store.getState();
      if (options.questionnaire) {
        const context = new SelectorsHub(options);
        return context.select.formErrors()(state);
      }
      return null;
    };

    const getSessionId = (meta) => {
      if (meta && meta.sessionId) {
        return meta.sessionId;
      }
      return undefined;
    };

    const save = new DelayedTask({
      delayMs: autosaveDelay,
      action: (
        name,
        {
          rawQuestionnaire,
          properties,
          variables,
          sessionId,
          answersSheetId,
        },
      ) => {
        // NOTE: It's fine even if sessionId is null, because if that's the case
        //       a new session will be created for us automatically.
        if (!answersSheetId) {
          return Promise.resolve();
        }
        const questionnaire =
          rawQuestionnaire && new Questionnaire(rawQuestionnaire);
        const {
          formValues,
          dynamicProperties,
        } = getNewFormValuesAndDynamicProperties({
          name,
          properties,
          variables,
          questionnaire,
        });
        // Here, we ensure answers for hidden questions are removed from local store.
        store.dispatch(replaceFormValues(name, formValues));
        // Here, we call the actual autosave method.
        if (handleAutosave) {
          return Promise.resolve(
            handleAutosave({
              sessionId,
              answersSheetId,
              formValues,
              dynamicProperties,
              questionnaire,
              getState: store.getState.bind(store),
              dispatch: store.dispatch.bind(store),
            }),
          );
        }
        return Promise.resolve();
      },
    });

    return next => (action) => {
      if (typeof action === 'function') {
        // NOTE: Just in case the thunk middleware is not in the pipeline.
        return store.dispatch(action(store.dispatch, store.getState));
      }
      switch (action.type) {
        case ACTION_SYNC_FORM_VALUES: {
          // NOTE: If there's a pending save, we cannot sync at this point
          //       because we would overwrite values which are required to
          //       perform the next save. Instead, we skip this sync request.
          //       This should not cause any problems though, because another
          //       sync will be triggered after the pending save is resolved.
          if (action.meta && save.isPending(action.meta.name)) {
            return next({
              ...action,
              meta: {
                ...action.meta,
                skip: true,
              },
            });
          }
          if (action.payload) {
            return next(action);
          }
          const {
            rawQuestionnaire,
            variables,
            answersSheetId,
          } =
            action.meta || {};
          const questionnaire =
            rawQuestionnaire && new Questionnaire(rawQuestionnaire);
          const sessionId = getSessionId(action.meta);
          const valueOrPromise = getInitialValues({
            sessionId,
            answersSheetId,
            variables,
            questionnaire,
            dispatch: store.dispatch.bind(store),
            getState: store.getState.bind(store),
          });
          if (valueOrPromise && typeof valueOrPromise.then === 'function') {
            return valueOrPromise
              .then(formValues => next({
                ...action,
                payload: formValues,
              }))
              .catch(() => {
                // ...
              });
          }
          return Promise.resolve(
            next({
              ...action,
              payload: valueOrPromise,
            }),
          );
        }
        case ACTION_FORCE_AUTOSAVE: {
          next(action);
          return save.runNow(action.meta.name);
        }
        case ACTION_VALIDATE_QUESTIONNAIRE: {
          next(action);
          const {
            name,
            rawQuestionnaire,
            properties,
            variables,
            dryRun,
          } =
            action.meta || {};
          const questionnaire =
            rawQuestionnaire && new Questionnaire(rawQuestionnaire);
          const formErrors = getCurrentFormErrors({
            name,
            questionnaire,
            properties,
            variables,
          });
          // TODO: Theoretically if there are no errors, then we should receive "null"
          //       here but for some reason it's an empty object sometimes. Using
          //       isEmpty to mitigate, but let's investigate later on.
          if (!isEmpty(formErrors) && !dryRun) {
            console.warn('validation errors:', formErrors);
            store.dispatch(replaceFormErrors(name, formErrors));
            return Promise.reject(
              new Error('Cannot complete because of validation errors'),
            );
          }
          const {
            formValues,
          } = getNewFormValuesAndDynamicProperties({
            name,
            questionnaire,
            properties,
            variables,
          });
          return Promise.resolve({
            formValues,
            formErrors,
            variables,
            questionnaire,
          });
        }
        default:
        // ...
      }

      switch (action.type) {
        case ACTION_SET_VALUE:
        case ACTION_ENTER_FORM_VALUES:
        case ACTION_INSERT_ELEMENT:
        case ACTION_REMOVE_ELEMENT: {
          const name = action.meta && action.meta.name;
          save.schedule(name, action.meta);
          break;
        }
        default:
        // ...
      }

      return next(action);
    };
  };
}

export default createQuestionnaireMiddleware;
