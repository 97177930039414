import {
  ENGLISH_VALUE,
  initI18next,
} from '../common/utils/i18next';

// eslint-disable-next-line import/prefer-default-export
export const supportedLanguages = process.env.REACT_APP_LANGUAGES
  ? process.env.REACT_APP_LANGUAGES.split(',')
  : [
    ENGLISH_VALUE,
  ];

const supportedNamespaces = [
  'common',
];

initI18next({
  supportedLanguages,
  supportedNamespaces,
});
