import memoize from 'lodash/memoize';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import pick from 'lodash/pick';
import {
  template,
} from '../text';
import {
  collapse,
} from '../formValues';

const getDefaultMutations = (evaluationScope) => {
  const questionnaire = evaluationScope.getQuestionnaire();
  return questionnaire && questionnaire.groupMutationsByQuestionId();
};

const dynamicProperties = [
  'votesToHide',
  'votesToHideChunk',
];

const templateProperties = [
  'title',
  'description',
];

/**
 * Create a tree structure (similar to form values), that represents current
 * dynamic properties values for all questions.
 *
 * @param {EvaluationScope} evaluationScope
 * @param {Object} [options]
 * @param {Object} [options.mutationsByQuestionId]
 * @param {Object} [options.propertiesDefaults]
 * @param {Object} [options.propertiesOverrides]
 */
function evaluateDynamicProperties(
  evaluationScope,
  {
    propertiesOverrides,
    mutationsByQuestionId = getDefaultMutations(evaluationScope),
  } = {},
) {
  const questionnaire = evaluationScope.getQuestionnaire();
  if (!questionnaire) {
    throw new Error(
      'evaluateDynamicProperties requires scope bound to a questionnaire',
    );
  }
  const evaluate = memoize(formula => formula.evaluate(evaluationScope));
  const getVariable = (id, {
    exclamationMark,
  }) => {
    let result;
    if (questionnaire.getQuestionById(id)) {
      result = evaluationScope.lookupAnswer(id);
    } else {
      result = evaluationScope.lookupVariable(id, exclamationMark);
    }
    if (!result) {
      return null;
    }
    if (result.error) {
      return '#ERR';
    }
    return collapse(result);
  };

  const nextResult = {};
  questionnaire.forEachQuestion(
    (question) => {
      const {
        _elements: elementsPropertiesOverrides,
        ...ownPropertiesOverride
      } = propertiesOverrides
        ? {
          ...propertiesOverrides['*'],
          ...propertiesOverrides[question.id],
        }
        : {};

      const mutations = mutationsByQuestionId[question.id];
      const initialValue = pick(question, dynamicProperties);

      let nextValue = reduce(
        mutations,
        (current, {
          formula,
          transform,
        }) => {
          const {
            value,
            error,
          } = evaluate(formula);
          if (!error && value) {
            return transform(current);
          }
          return current;
        },
        initialValue,
      );

      forEach(templateProperties, (prop) => {
        if (question[prop]) {
          // NOTE: In the future we may consider more sophisticated templating
          //       functions, e.g. conditionals or loops. If this is the case
          //       then templates will need to be compiled at the question level
          //       and cached.
          const value = template(question[prop])(getVariable);
          if (value !== question[prop]) {
            nextValue = {
              ...nextValue,
              [prop]: value,
            };
          }
        }
      });

      if (question.isCollection()) {
        const answer = evaluationScope.getAnswer(question.id);
        const newElements = {};
        forEach(answer && answer._elementsOrder, (elementId) => {
          const elementOverrides =
            elementsPropertiesOverrides &&
            elementsPropertiesOverrides[elementId] &&
            elementsPropertiesOverrides[elementId]._elements;
          newElements[elementId] = {
            _elements: evaluateDynamicProperties(
              evaluationScope
                .getOrCreateSubScope(question.id)
                .getOrCreateSubScope(elementId),
              {
                mutationsByQuestionId,
                propertiesOverrides: {
                  ...elementOverrides,
                  '*': {
                    ...(propertiesOverrides && propertiesOverrides['*']),
                    ...(elementOverrides && elementOverrides['*']),
                  },
                },
              },
            ),
          };
          // NOTE: This means nothing interesting happened there, and the
          //       nested properties are equal to the initial values.
          if (!newElements[elementId]._elements) {
            delete newElements[elementId];
          }
        });
        if (!isEmpty(newElements)) {
          nextValue = {
            ...nextValue,
            _elements: newElements,
          };
        }
      }

      if (!isEmpty(ownPropertiesOverride)) {
        nextValue = {
          ...nextValue,
          ...ownPropertiesOverride,
        };
      }

      const diffObject = {};
      forEach(nextValue, (value, key) => {
        if (value !== question[key]) {
          diffObject[key] = value;
        }
      });

      if (!isEmpty(diffObject)) {
        nextResult[question.id] = diffObject;
      }
    },
    {
      sectionId: evaluationScope.getCollectionQuestionId(),
      stopRecursion: q => q.isCollection(),
    },
  );
  if (isEmpty(nextResult)) {
    return null;
  }
  return nextResult;
}

export default evaluateDynamicProperties;
