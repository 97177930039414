import React, {
  lazy,
  Suspense,
} from 'react';
import PropTypes from 'prop-types';
import {
  connect,
} from 'react-redux';
import {
  createStructuredSelector,
} from 'reselect';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import {
  ConnectedRouter,
} from 'connected-react-router';
import {
  useTranslation,
} from 'react-i18next';
import NotFound from '../common/components/NotFound';
import Loading from '../common/components/Loading';
import Cover from '../common/components/primitives/Cover';
import LandingSkeleton from '../screens/Landing/LandingSkeleton';
import GlobalStyle from '../GlobalStyle';
import RouterTransition from './RouterTransition';

const Landing = lazy(() => import('../screens/Landing'));
const Form = lazy(() => import('../screens/Form'));
const ThankYou = lazy(() => import('../screens/ThankYou'));
const ContactUs = lazy(() => import('../screens/ContactUs'));
const FAQ = lazy(() => import('../screens/FAQ'));
const FormBuilder = lazy(() => import('../screens/FormBuilder'));

const withLandingFallback = (Component) => {
  return props => (
    <Suspense fallback={<LandingSkeleton />}>
      <Component
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Suspense>
  );
};

const Routes = connect(() => createStructuredSelector({
  location: state => state.router.location,
}))(({
  location,
}) => {
  const {
    i18n,
  } = useTranslation();

  const direction = i18n.dir(i18n.language);

  return (
    <RouterTransition location={location}>
      <Cover
        space={0}
        centeredSelector="main"
        isScrollable
      >
        <GlobalStyle direction={direction} />
        <Suspense fallback={<Loading />}>
          <Switch location={location}>
            <Route
              exact
              path="/"
              render={() => <Redirect to="/activities/" />}
            />
            <Route
              path="/activities/"
              exact
              component={withLandingFallback(Landing)}
            />
            <Route
              path="/forms/:answersSheetId/:questionId?"
              component={Form}
            />
            <Route
              path="/thank-you/:answersSheetId?"
              component={ThankYou}
            />
            <Route
              path="/faq"
              exact
              component={FAQ}
            />
            <Route
              path="/contact-us"
              exact
              component={ContactUs}
            />
            <Route
              exact
              path="/form-builder"
              component={FormBuilder}
            />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Cover>
    </RouterTransition>
  );
});

const Router = ({
  history,
}) => (
  <ConnectedRouter history={history}>
    <Routes />
  </ConnectedRouter>
);

Router.propTypes = {
  history: PropTypes.object.isRequired, //  eslint-disable-line react/forbid-prop-types
};

export default Router;
