import styled from 'styled-components';
import theming from 'styled-theming';

const themeModes = {
  light: '#334053',
  dark: '#f2f2f3',
};

const fill = theming('mode', themeModes);

// eslint-disable-next-line import/prefer-default-export
export const Component = styled.svg`
  fill: ${props => (props.themeMode ? themeModes[props.themeMode] : fill)};
`;
