import styled from 'styled-components/macro';
import React, {
  Suspense,
} from 'react';
import PropTypes from 'prop-types';
import Stack from '../primitives/Stack';
import Grid from '../primitives/Grid';
import Cluster from '../primitives/Cluster';
import Box from '../primitives/Box';
import Markdown from '../Markdown';
import Card from './Card';
import {
  theme,
} from '../../utilsClient/cssHelpers';

// TODO: Add back support for icons and images

// {icon && (
//   <StyledIcon>
//     <img
//       // eslint-disable-next-line global-require, import/no-dynamic-require
//       src={require(`../../../common/assets/icons/${icon}.svg`)}
//       alt={icon}
//     />
//   </StyledIcon>
// )}

// {!!imageUrl && (
//   <PosedContainer>
//     <StyledImg
//       src={imageUrl}
//       alt={prefix}
//     />
//   </PosedContainer>
// )}

const StyledBox = styled(Box)`
  &:first-child:not(:last-child) {
    padding-bottom: 0;
  }
`;

const StyledStack = styled(Stack)`
  /* TODO: Do we still need it?  */
  ol,
  ul,
  dl {
    padding-left: ${theme('space.3')};
  }
`;

const CardQuestionLayout = ({
  // eslint-disable-next-line react/prop-types
  extra,
  // eslint-disable-next-line react/prop-types
  children,
}) => {
  return (
    <Suspense fallback={<div>loading...</div>}>
      {extra ? (
        <Grid
          space={0}
          align="flex-start"
        >
          {children}
        </Grid>
      ) : (
        children
      )}
    </Suspense>
  );
};

CardQuestionLayout.propTypes = {
  extra: PropTypes.node,
  children: PropTypes.node,
};

CardQuestionLayout.defaultProps = {
  extra: null,
  children: null,
};

const CardQuestion = ({
  title,
  description,
  extra,
  children,
}) => {
  return (
    <Card space={0}>
      <CardQuestionLayout extra={extra}>
        <StyledBox>
          <Stack>
            <StyledStack>
              {title && <Markdown source={`## ${title}`} />}
              {description && <Markdown source={description} />}
            </StyledStack>
            {extra}
          </Stack>
        </StyledBox>
        {extra ? <Cluster justify="center">{children}</Cluster> : children}
      </CardQuestionLayout>
    </Card>
  );
};

CardQuestion.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  extra: PropTypes.node,
};

CardQuestion.defaultProps = {
  title: null,
  description: null,
  extra: null,
};

export default CardQuestion;
