/* eslint global-require: "off" */
import forEach from 'lodash/forEach';
import {
  ApolloLink,
} from 'apollo-link';
import {
  HttpLink,
} from 'apollo-link-http';
import {
  setContext,
} from 'apollo-link-context';
import {
  onError,
} from 'apollo-link-error';
import {
  InMemoryCache,
} from 'apollo-cache-inmemory';
import {
  CachePersistor,
} from 'apollo-cache-persist';
import ApolloClient from 'apollo-client';
import storage from 'localforage';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  createSelector,
} from 'reselect';
import {
  createBrowserHistory,
} from 'history';
import settings from './common/settings';
import {
  matchToken,
} from './common/utils/history';
import App from './containers/App';
import resolveOnSelector from './common/utilsClient/redux/resolveOnSelector';
import configureStore from './store/configureStore';
import {
  setToken,
  selectToken,
} from './store/token';
import isDebug from './utils/isDebug';
import logger from './common/logger';
import './common/logger/client/register';
import './common/fonts';
import messageBus from './utils/messageBus';
import * as serviceWorker from './serviceWorker';
import './errorLogs';

const history = createBrowserHistory();
const cache = new InMemoryCache();
const cachePersistor = new CachePersistor({
  cache,
  storage,
});

let store;
let persistor;
const authLink = setContext(async (_, {
  headers,
}) => {
  const token = await resolveOnSelector(
    store,
    createSelector(
      selectToken,
      state => state.router && state.router.location,
      (currentToken, location) => {
        const newToken = matchToken(location.hash);
        return newToken || currentToken;
      },
    ),
  );
  return {
    headers: {
      ...headers,
      Authorization: token,
    },
  };
});

const errorLink = onError(({
  networkError,
  graphQLErrors,
}) => {
  if (graphQLErrors) {
    forEach(graphQLErrors, ({
      message,
      locations,
      path,
    }) => {
      messageBus.emit('apolloError', {
        errorType: 'graphQLError',
        message,
        locations,
        path,
      });
    });
  }
  if (networkError) {
    if (networkError.statusCode === 401) {
      messageBus.emit('apolloError', {
        errorType: 'tokenError',
        messageKey: 'jwterror',
      });
      // NOTE: This will result in emitting loggedOut event.
      store.dispatch(setToken(null));
    }
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([
    authLink,
    errorLink,
    new HttpLink({
      uri: settings.public.patientGraphqlUrl,
      fetchOptions: {
        mode: 'cors',
        credentials: 'same-origin',
      },
    }),
  ]),
  cache,
  connectToDevTools: isDebug,
});

({
  store,
  persistor, // eslint-disable-line prefer-const
} = configureStore({
  history,
  client,
  cache,
}));

const initialLoading = Promise.all([
  cachePersistor.restore(),
  resolveOnSelector(persistor, 'bootstrapped'),
]);

ReactDOM.render(
  <App
    store={store}
    history={history}
    client={client}
    promise={initialLoading}
  />,
  document.getElementById('root'),
);

if (process.env.NODE_ENV !== 'production') {
  if (typeof module !== 'undefined' && module.hot) {
    module.hot.accept('./containers/App', () => {
      const NextApp = require('./containers/App').default;
      ReactDOM.render(
        <NextApp
          store={store}
          history={history}
          client={client}
          promise={initialLoading}
        />,
        document.getElementById('root'),
      );
    });
  }
}

serviceWorker.register({
  onUpdate: (registration) => {
    if (registration.waiting) {
      registration.waiting.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });

      registration.waiting.postMessage({
        type: 'SKIP_WAITING',
      });
    }
  },
});

// Make sure cache is purged on token removal.
messageBus.on('loggedOut', () => {
  Promise.all([
    client.resetStore(),
    persistor.purge(),
    cachePersistor.purge(),
  ]).catch((err) => {
    logger.error(err.toString(), {
      stack: err.stack,
    });
  });
});
