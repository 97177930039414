import PropTypes from 'prop-types';
import React from 'react';
import {
  useSelector,
} from 'react-redux';
import TransitionRoute from '../common/components/transitions/TransitionRoute';
import {
  getAnimationSpeed,
} from '../store/preferences';

const getKey = pathname => pathname.split('/')[1];

const speedFactor = 1;

const RouterTransition = ({
  location,
  children,
}) => {
  const animationSpeed = useSelector(getAnimationSpeed);

  return (
    <TransitionRoute
      id={getKey(location.pathname)}
      duration={(animationSpeed * speedFactor) / 1000}
    >
      {children}
    </TransitionRoute>
  );
};

RouterTransition.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default RouterTransition;
