export const QUESTION_TYPE__SELECT = 'select';
export const QUESTION_TYPE__SELECT_MANY = 'selectMany';
export const QUESTION_TYPE__SELECT_ONE = 'selectOne';
export const QUESTION_TYPE__SHORT_TEXT = 'shortText';
export const QUESTION_TYPE__COLLECTION = 'collection';
export const QUESTION_TYPE__EMPTY = 'empty'; // it only has title
export const QUESTION_TYPE__SECTION = 'section';
export const QUESTION_TYPE__MATRIX = 'matrix';
export const QUESTION_TYPE__UNKNOWN = 'unknown';
export const QUESTION_TYPE__FREE_TEXT = 'freeText';
export const QUESTION_TYPE__SCORES = 'scores';
export const QUESTION_TYPE__NUMBER = 'number';
export const QUESTION_TYPE__NUMBER_WITH_UNIT = 'numberWithUnit';
export const QUESTION_TYPE__SCALE = 'scale';
export const QUESTION_TYPE__DATE = 'date'; // This is a datetime, not just date
export const QUESTION_TYPE__TIME = 'time';
export const QUESTION_TYPE__UPLOAD = 'upload';
export const QUESTION_TYPE__FORMULA = 'formula';
export const QUESTION_TYPE__SWITCH = 'switch';
export const QUESTION_TYPE__SIGNATURE = 'signature';
export const QUESTION_TYPE__IFRAME = 'iframe';
export const QUESTION_TYPE__BLUETOOTH_DEVICE = 'bluetoothDevice';
export const QUESTION_TYPE__MEDIA = 'media';
export const QUESTION_TYPE__RAW_JSON = 'rawJson';

export const QUESTION_TYPES = [
  QUESTION_TYPE__SCORES,
  QUESTION_TYPE__SELECT,
  QUESTION_TYPE__SELECT_MANY,
  QUESTION_TYPE__SELECT_ONE,
  QUESTION_TYPE__SHORT_TEXT,
  QUESTION_TYPE__EMPTY,
  QUESTION_TYPE__SECTION,
  QUESTION_TYPE__MATRIX,
  QUESTION_TYPE__UNKNOWN,
  QUESTION_TYPE__FREE_TEXT,
  QUESTION_TYPE__NUMBER,
  QUESTION_TYPE__NUMBER_WITH_UNIT,
  QUESTION_TYPE__SCALE,
  QUESTION_TYPE__DATE,
  QUESTION_TYPE__TIME,
  QUESTION_TYPE__UPLOAD,
  QUESTION_TYPE__FORMULA,
  QUESTION_TYPE__COLLECTION,
  QUESTION_TYPE__SWITCH,
  QUESTION_TYPE__SIGNATURE,
  QUESTION_TYPE__IFRAME,
  QUESTION_TYPE__BLUETOOTH_DEVICE,
  QUESTION_TYPE__MEDIA,
  QUESTION_TYPE__RAW_JSON,
];

// NOTE: The questions which are commented out are not yet ready to be presented.
//       Let me briefly describe problems that will need to be solved:
//
//       (1) mdl-slider is not emitting neither change nor input event, which
//           is preventing AntiForm from detecting an answer, so it's ignored
//           https://github.com/google/material-design-lite/issues/4397

export const QUESTION_TYPE_OPTIONS = [
  {
    value: QUESTION_TYPE__EMPTY,
    label: 'Empty',
  },
  {
    value: QUESTION_TYPE__SECTION,
    label: 'Section',
  },
  {
    value: QUESTION_TYPE__MATRIX,
    label: 'Matrix',
  },
  {
    value: QUESTION_TYPE__SELECT_ONE,
    label: 'Select One',
  },
  {
    value: QUESTION_TYPE__SELECT_MANY,
    label: 'Select Many',
  },
  {
    value: QUESTION_TYPE__SHORT_TEXT,
    label: 'Short Text',
  },
  {
    value: QUESTION_TYPE__FREE_TEXT,
    label: 'Free Text',
  },
  {
    value: QUESTION_TYPE__SCORES,
    label: 'Scores',
  },
  {
    value: QUESTION_TYPE__NUMBER,
    label: 'Number',
  },
  {
    value: QUESTION_TYPE__NUMBER_WITH_UNIT,
    label: 'Number & Unit',
  },
  {
    value: QUESTION_TYPE__SCALE,
    label: 'Scale',
  },
  {
    value: QUESTION_TYPE__DATE,
    label: 'Date',
  },
  {
    value: QUESTION_TYPE__TIME,
    label: 'Time',
  },
  {
    value: QUESTION_TYPE__UPLOAD,
    label: 'Upload',
  },
  {
    value: QUESTION_TYPE__FORMULA,
    label: 'Formula',
  },
  {
    value: QUESTION_TYPE__COLLECTION,
    label: 'Collection',
  },
  {
    value: QUESTION_TYPE__SWITCH,
    label: 'Switch',
  },
  {
    value: QUESTION_TYPE__SIGNATURE,
    label: 'Signature',
  },
  {
    value: QUESTION_TYPE__IFRAME,
    label: 'Iframe',
  },
  {
    value: QUESTION_TYPE__BLUETOOTH_DEVICE,
    label: 'Bluetooth device',
  },
  {
    value: QUESTION_TYPE__MEDIA,
    label: 'Media',
  },
  {
    value: QUESTION_TYPE__RAW_JSON,
    label: 'Raw JSON value (for internal usage only)',
  },
];

export const QUESTIONNAIRE_DISPLAY_MODE__PRESENTATION = 'presentation';
export const QUESTIONNAIRE_DISPLAY_MODE__SINGLE_PAGE = 'singlePage';
export const QUESTIONNAIRE_DISPLAY_MODE__OPERATOR = 'operator';
export const QUESTIONNAIRE_DISPLAY_MODES = [
  QUESTIONNAIRE_DISPLAY_MODE__PRESENTATION,
  QUESTIONNAIRE_DISPLAY_MODE__SINGLE_PAGE,
  QUESTIONNAIRE_DISPLAY_MODE__OPERATOR,
];

export const QUESTIONNAIRE_MODE__RECRUITMENT = 'recruitment';
export const QUESTIONNAIRE_MODE__SURVEY = 'survey';
export const QUESTIONNAIRE_MODE__COMBINED = 'combined';
export const QUESTIONNAIRE_MODE__NOTHING = 'nothing';
export const QUESTIONNAIRE_MODES = [
  QUESTIONNAIRE_MODE__RECRUITMENT,
  QUESTIONNAIRE_MODE__SURVEY,
  QUESTIONNAIRE_MODE__COMBINED,
  QUESTIONNAIRE_MODE__NOTHING,
];
export const QUESTIONNAIRE_MODE_OPTIONS = [
  {
    value: QUESTIONNAIRE_MODE__RECRUITMENT,
    label: 'Recruitment only',
  },
  {
    value: QUESTIONNAIRE_MODE__SURVEY,
    label: 'Survey only',
  },
  {
    value: QUESTIONNAIRE_MODE__COMBINED,
    label: 'All in one',
  },
  {
    value: QUESTIONNAIRE_MODE__NOTHING,
    label: 'Nothing',
  },
];
export const QUESTION_NUMBERING_STYLE__ALPHABET = 'alphabet';
export const QUESTION_NUMBERING_STYLE__ARABIC = 'arabic';
export const QUESTION_NUMBERING_STYLE__ROMAN = 'roman';
export const QUESTION_NUMBERING_STYLE__SMALL_ALPHABET = 'smallAlphabet';
export const QUESTION_NUMBERING_STYLE__SMALL_ROMAN = 'smallRoman';
export const QUESTION_NUMBERING_STYLES = [
  QUESTION_NUMBERING_STYLE__ALPHABET,
  QUESTION_NUMBERING_STYLE__ARABIC,
  QUESTION_NUMBERING_STYLE__ROMAN,
  QUESTION_NUMBERING_STYLE__SMALL_ALPHABET,
  QUESTION_NUMBERING_STYLE__SMALL_ROMAN,
];
export const QUESTION_NUMBERING_STYLE_OPTIONS = [
  {
    value: QUESTION_NUMBERING_STYLE__ALPHABET,
    label: 'A, B, C',
  },
  {
    value: QUESTION_NUMBERING_STYLE__ARABIC,
    label: '1, 2, 3',
  },
  {
    value: QUESTION_NUMBERING_STYLE__ROMAN,
    label: 'I, II, III',
  },
  {
    value: QUESTION_NUMBERING_STYLE__SMALL_ALPHABET,
    label: 'a, b, c',
  },
  {
    value: QUESTION_NUMBERING_STYLE__SMALL_ROMAN,
    label: 'i, ii, iii',
  },
];

export const SECTION_TYPE__NORMAL = 'normal';
export const SECTION_TYPE__TABS = 'tabs';
export const SECTION_TYPE__SINGLE_PAGE = 'singlePage';
export const SECTION_TYPE__COMPACT = 'compact';

export const SECTION_TYPES = [
  // NOTE: The first is the considered the default one.
  SECTION_TYPE__NORMAL,
  SECTION_TYPE__TABS,
  SECTION_TYPE__SINGLE_PAGE,
  SECTION_TYPE__COMPACT,
];

export const SECTION_TYPE_OPTIONS = [
  {
    value: SECTION_TYPE__NORMAL,
    label: 'Normal',
  },
  {
    value: SECTION_TYPE__TABS,
    label: 'Tabs',
  },
  {
    value: SECTION_TYPE__SINGLE_PAGE,
    label: 'Single Page',
  },
  {
    value: SECTION_TYPE__COMPACT,
    label: 'Compact',
  },
];

export const QUESTION_MEDIA_TYPE__VIDEO = 'video';
export const QUESTION_MEDIA_TYPE__AUDIO = 'audio';
export const QUESTION_MEDIA_TYPE__IMAGE = 'image';

export const QUESTION_MEDIA_TYPES = [
  QUESTION_MEDIA_TYPE__VIDEO,
  QUESTION_MEDIA_TYPE__AUDIO,
  QUESTION_MEDIA_TYPE__IMAGE,
];

export const QUESTION_MEDIA_TYPE_OPTIONS = [
  {
    value: QUESTION_MEDIA_TYPE__VIDEO,
    label: 'Video',
  },
  // NOTE: These types are currently not supported
  //       in patient app anyway, so let's hide them.
  // {
  //   value: QUESTION_MEDIA_TYPE__AUDIO,
  //   label: 'Audio',
  // },
  // {
  //   value: QUESTION_MEDIA_TYPE__IMAGE,
  //   label: 'Image',
  // },
];

export const SCREEN_LAYOUT__ONE_QUESTION = 'oneQuestion';
export const SCREEN_LAYOUT__TABS = 'tabs';
export const SCREEN_LAYOUT__INLINE = 'inline';
export const SCREEN_LAYOUT__OPERATOR = 'operator';

export const SCREEN_LAYOUTS = [
  // NOTE: The first is the considered the default one.
  SCREEN_LAYOUT__ONE_QUESTION,
  SCREEN_LAYOUT__TABS,
  SCREEN_LAYOUT__INLINE,
  SCREEN_LAYOUT__OPERATOR,
];

// NOTE: Operator layout is not exposed in the ui explicitly.
export const SCREEN_LAYOUT_OPTIONS = [
  {
    value: SCREEN_LAYOUT__ONE_QUESTION,
    label: 'Normal',
  },
  {
    value: SCREEN_LAYOUT__TABS,
    label: 'Tabs',
  },
  {
    value: SCREEN_LAYOUT__INLINE,
    label: 'Inline',
  },
  {
    value: SCREEN_LAYOUT__OPERATOR,
    label: 'Operator',
  },
];

export const AGGREGATION_TYPE__LAST = 'last';
export const AGGREGATION_TYPE__FIRST = 'first';
export const AGGREGATION_TYPE__MIN = 'min';
export const AGGREGATION_TYPE__MAX = 'max';
export const AGGREGATION_TYPE__SUM = 'sum';
export const AGGREGATION_TYPE__AVG = 'avg';
export const AGGREGATION_TYPES = [
  AGGREGATION_TYPE__LAST,
  AGGREGATION_TYPE__FIRST,
  AGGREGATION_TYPE__MIN,
  AGGREGATION_TYPE__MAX,
  AGGREGATION_TYPE__SUM,
  AGGREGATION_TYPE__AVG,
];

export const AGGREGATION_TYPE_OPTIONS = [
  {
    value: AGGREGATION_TYPE__SUM,
    label: 'Sum values',
  },
  {
    value: AGGREGATION_TYPE__MIN,
    label: 'Min value',
  },
  {
    value: AGGREGATION_TYPE__MAX,
    label: 'Max value',
  },
  {
    value: AGGREGATION_TYPE__AVG,
    label: 'Average value',
  },
];
