import {
  createGlobalStyle,
  css,
} from 'styled-components';
import globalStyles from './common/utilsClient/globalStyles';
import {
  media,
} from './common/utilsClient/cssHelpers';

const mediaStyles = css`
  ${media.greaterThan('mobile')`
    html {
      font-size: 100%;
    }
  `}
`;

const GlobalStyle = createGlobalStyle`
  ${globalStyles};

  h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
  }

  /* App */

  #root {
    &,
    & > div {
      height: 100%;
      overflow: hidden;
    }
  }

  /* List */

  ul.ant-list-item-action {
    
    li {
      margin-bottom: 0;
    }
  }

  ul:not(role=menu) li {
    margin-bottom: 0.5em;
  }

  /* Media Player */

  .plyr--video {
    background: none !important;
  }

  /* Helpers */

  .overflow-hidden {
    overflow: hidden !important;
  }

  /* Localisation  */

  body {
    direction: ${props => props.direction};
  }

  /* Media */

  ${mediaStyles};
`;

export default GlobalStyle;
