import styled from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import {
  motion,
  AnimatePresence,
} from 'framer-motion';
import usePrefersReducedMotion from '../../utils/usePrefersReducedMotion';

const StyledMotion = styled(motion.div)`
  &,
  & > div {
    height: 100%;
  }
`;

const TransitionRoute = ({
  children,
  id,
  duration,
}) => {
  const prefersReducedMotion = usePrefersReducedMotion();

  const variants =
    prefersReducedMotion || !duration
      ? {}
      : {
        initial: {
          y: 25,
          opacity: 0,
          transition: {
            duration,
          },
        },
        animate: {
          y: 0,
          opacity: 1,
          transition: {
            ease: 'easeOut',
            when: 'beforeChildren',
            staggerChildren: 0.5,
            duration,
          },
        },
        exit: {
          y: 25,
          opacity: 0,
          transition: {
            ease: 'easeIn',
            delay: duration,
            duration,
          },
        },
      };

  return (
    <AnimatePresence
      initial={false}
      exitBeforeEnter
    >
      <StyledMotion
        key={id}
        initial="initial"
        animate="animate"
        exit="exit"
        variants={variants}
      >
        {children}
      </StyledMotion>
    </AnimatePresence>
  );
};

TransitionRoute.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  duration: PropTypes.number,
};

TransitionRoute.defaultProps = {
  duration: 0.4,
};

export default TransitionRoute;
