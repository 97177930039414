import {
  useEffect,
  useState,
} from 'react';

export default () => {
  if (typeof matchMedia !== 'function') {
    return null;
  }

  const media = matchMedia('(prefers-reduced-motion)');
  const [
    prefersReducedMotion,
    setPrefersReducedMotion,
  ] = useState(
    // Initial value should be 'true' or 'null' i.e. 'no-preference'
    media.matches || null,
  );

  useEffect(() => {
    const handleMediaChange = event => setPrefersReducedMotion(event.matches);

    if (matchMedia.addEventListener) {
      media.addEventListener('change', handleMediaChange);
    } else {
      // polyfil for Safari and IE
      media.addListener((mql) => {
        if (mql.matches) {
          handleMediaChange(mql);
        }
      });
    }

    return () => (media.removeEventListener
      ? media.removeEventListener('change', handleMediaChange)
      : media.removeListener(handleMediaChange));
  });

  return prefersReducedMotion;
};
