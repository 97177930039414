import Formula from '../Formula';
import {
  FORMULA_TYPE__BINARY,
  FORMULA_OPERATOR__SUM,
  FORMULA_OPERATOR__SUB,
  FORMULA_OPERATOR__PROD,
  FORMULA_OPERATOR__DIV,
  FORMULA_OPERATOR__AND,
  FORMULA_OPERATOR__OR,
  FORMULA_OPERATOR__IF_ERROR,
  FORMULA_OPERATOR__CONCAT,
  FORMULA_OPERATOR__MIN,
  FORMULA_OPERATOR__MAX,
  FORMULA_OPERATOR__WHITESPACE,
} from '../../../constants';
import Schema from '../../../utils/Schema';
import operators from '../operators';

const settingsSchema = new Schema({
  operator: {
    type: String,
  },
  left: {
    type: Object,
    additionalProperties: true,
  },
  right: {
    type: Object,
    additionalProperties: true,
  },
});

const castType = (scope, operator, value) => {
  switch (operator) {
    case FORMULA_OPERATOR__SUM:
    case FORMULA_OPERATOR__SUB:
    case FORMULA_OPERATOR__PROD:
    case FORMULA_OPERATOR__DIV:
      return scope.evaluateAsNumber(value);
    default:
      return {
        value,
      };
  }
};

// eslint-disable-next-line import/prefer-default-export
export class FormulaBinary extends Formula {
  validate() {
    if (!this.settings) {
      return this.constructor.NotConfigured;
    }
    if (settingsSchema.getErrors(this.settings)) {
      return this.constructor.NotConfigured;
    }
    return undefined;
  }

  evaluate(scope) {
    const op = operators[this.settings.operator];
    if (!op) {
      return {
        error: this.constructor.UnknownOperator,
      };
    }
    let left = Formula.create(this.settings.left).evaluate(scope);
    if (!left.error) {
      left = castType(scope, this.settings.operator, left.value);
    }

    if (
      this.settings.operator !== FORMULA_OPERATOR__OR &&
      this.settings.operator !== FORMULA_OPERATOR__IF_ERROR &&
      left.error
    ) {
      return {
        error: left.error,
      };
    }

    switch (this.settings.operator) {
      case FORMULA_OPERATOR__OR: {
        if (!left.error && left.value) {
          return left;
        }
        break;
      }
      case FORMULA_OPERATOR__AND: {
        if (!left.value) {
          return left;
        }
        break;
      }
      case FORMULA_OPERATOR__IF_ERROR: {
        if (!left.error) {
          return left;
        }
        break;
      }
      default:
      // ...
    }

    let right = Formula.create(this.settings.right).evaluate(scope);
    if (!right.error) {
      right = castType(scope, this.settings.operator, right.value);
    }
    if (right.error) {
      return {
        error: right.error,
      };
    }

    return {
      value: op(left.error ? null : left.value, right.value),
    };
  }

  compile(questionsHierarchy) {
    const compiled = {
      ...this,
      settings: {
        operator: this.settings.operator,
      },
    };
    if (this.settings && this.settings.left) {
      compiled.settings.left = Formula.create(this.settings.left).compile(
        questionsHierarchy,
      );
    }
    if (this.settings && this.settings.right) {
      compiled.settings.right = Formula.create(this.settings.right).compile(
        questionsHierarchy,
      );
    }
    return compiled;
  }

  toMongoExpression() {
    if (!this.settings.left || !this.settings.right) {
      return {
        $literal: '[unknown]',
      };
    }
    const left = this.settings.left.toMongoExpression();
    const right = this.settings.right.toMongoExpression();
    switch (this.settings.operator) {
      case FORMULA_OPERATOR__SUM:
        return {
          $add: [
            left,
            right,
          ],
        };
      case FORMULA_OPERATOR__SUB:
        return {
          $subtract: [
            left,
            right,
          ],
        };
      case FORMULA_OPERATOR__PROD:
        return {
          $multiply: [
            left,
            right,
          ],
        };
      case FORMULA_OPERATOR__DIV:
        return {
          $divide: [
            left,
            right,
          ],
        };
      case FORMULA_OPERATOR__AND:
        return {
          $cond: [
            left,
            right,
            left,
          ],
        };
      case FORMULA_OPERATOR__OR:
        return {
          $cond: [
            left,
            left,
            right,
          ],
        };
      case FORMULA_OPERATOR__WHITESPACE:
        return {
          $concat: [
            left,
            ' ',
            right,
          ],
        };
      case FORMULA_OPERATOR__CONCAT:
        return {
          $concat: [
            left,
            right,
          ],
        };
      case FORMULA_OPERATOR__MIN:
        return {
          $min: [
            left,
            right,
          ],
        };
      case FORMULA_OPERATOR__MAX:
        return {
          $max: [
            left,
            right,
          ],
        };
      // case FORMULA_OPERATOR__LOOKUP:
      default:
        return {
          $literal: '[unknown]',
        };
    }
  }

  static createMapSettings(mapQuestionId) {
    return (value, key) => {
      switch (key) {
        case 'left':
        case 'right':
          return Formula.create(value).remap(mapQuestionId).toRawFormula();
        default:
          return value;
      }
    };
  }

  static ifError(left, right) {
    return new this({
      type: FORMULA_TYPE__BINARY,
      settings: {
        operator: FORMULA_OPERATOR__IF_ERROR,
        left: left.toRawFormula(),
        right: right.toRawFormula(),
      },
    });
  }

  static or(left, right) {
    return new this({
      type: FORMULA_TYPE__BINARY,
      settings: {
        operator: FORMULA_OPERATOR__OR,
        left: left.toRawFormula(),
        right: right.toRawFormula(),
      },
    });
  }

  static and(left, right) {
    return new this({
      type: FORMULA_TYPE__BINARY,
      settings: {
        operator: FORMULA_OPERATOR__AND,
        left: left.toRawFormula(),
        right: right.toRawFormula(),
      },
    });
  }
}

Formula.types[FORMULA_TYPE__BINARY] = FormulaBinary;
