import map from 'lodash/map';
import find from 'lodash/find';
import React, {
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  push,
} from 'connected-react-router';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  useTranslation,
} from 'react-i18next';
import {
  PlayCircleOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import Select, {
  StyledSelect,
} from '../../common/components/Select';
import Stack from '../../common/components/primitives/Stack';
import Spacer from '../../common/components/primitives/Spacer';
import Cluster from '../../common/components/primitives/Cluster';
import Text from '../../common/components/base/Text';
import Button from '../../common/components/Button';
import useQuestionnaire, {
  useQuestionnaireTranslation,
} from '../../utils/useQuestionnaire';
import tracker from '../../utils/tracker';
import useReconcile from '../../common/utilsClient/useReconcile';
import {
  createSelectTranslationId,
  createSelectTranslationLanguage,
  setTranslation,
  save,
} from '../../store/stage';
import {
  selectPreferredUiLanguage,
  createSelectPreferredLanguage,
} from '../../store/preferences';

const SelectOption = Select.Option;

const AnswersSheetButtons = ({
  currentProjectLanguage,
  answersSheetId,
}) => {
  const uiLanguage = useSelector(selectPreferredUiLanguage);
  const previousTranslationId = useSelector(
    createSelectTranslationId(answersSheetId),
  );
  const previousTranslationLanguage = useSelector(
    createSelectTranslationLanguage(answersSheetId),
  );
  const {
    t,
  } = useTranslation();
  const {
    isFirstInActivity,
    isStarted,
    isCompleted,
    mostRecentDraft,
    localDraftNeedsUpdate,
    loadingQuestionnaire,
    translations,
    currentQuestionId,
  } = useQuestionnaire(answersSheetId, null, {
    preferRemoteDraftIfNewer: true,
  });
  const allLanguages = useReconcile(map(translations, 'language'));
  const defaultTranslationLanguage = useSelector(
    createSelectPreferredLanguage(allLanguages),
  );
  const defaultTranslationId = useMemo(() => {
    const translation = find(translations, {
      language: defaultTranslationLanguage,
    });
    return translation && translation.id;
  }, [
    translations,
    defaultTranslationLanguage,
  ]);
  let currentTranslationId;
  let currentTranslationLanguage;
  if (previousTranslationId) {
    currentTranslationId = previousTranslationId;
    currentTranslationLanguage = previousTranslationLanguage;
  } else if (defaultTranslationId) {
    currentTranslationId = defaultTranslationId;
    currentTranslationLanguage = defaultTranslationLanguage;
  } else {
    currentTranslationId =
      translations && translations[0] && translations[0].id;
    currentTranslationLanguage =
      translations && translations[0] && translations[0].language;
  }
  useQuestionnaireTranslation(answersSheetId, currentTranslationId);
  // NOTE: Only preload translation for better UX, but do not block current UI.
  const dispatch = useDispatch();
  const handleOnSelectTranslation = useCallback(
    (translationId, option) => {
      const language = option.props['data-language'];
      dispatch(
        setTranslation(answersSheetId, translationId, language, allLanguages),
      );
    },
    [
      dispatch,
      answersSheetId,
      allLanguages,
    ],
  );
  const handleOnStart = useCallback(() => {
    if (currentTranslationId) {
      dispatch(
        setTranslation(
          answersSheetId,
          currentTranslationId,
          currentTranslationLanguage,
          allLanguages,
        ),
      );
      if (mostRecentDraft && localDraftNeedsUpdate) {
        dispatch(save(answersSheetId, mostRecentDraft));
      }
    }
    if (currentQuestionId) {
      tracker.trackEvent('Questionnaire', 'Start');

      dispatch(push(`/forms/${answersSheetId}/${currentQuestionId}`));
    }
  }, [
    dispatch,
    answersSheetId,
    currentQuestionId,
    currentTranslationId,
    currentTranslationLanguage,
    allLanguages,
    mostRecentDraft,
    localDraftNeedsUpdate,
  ]);
  const showLanguageSelect = loadingQuestionnaire
    ? currentProjectLanguage !== uiLanguage
    : !(
      currentTranslationLanguage === currentProjectLanguage &&
        currentTranslationLanguage === uiLanguage
    );
  return (
    <Stack space={1}>
      <Cluster>
        {!isCompleted && (
          <Button
            data-testid="button-start-q"
            type="cta"
            size="large"
            icon={<PlayCircleOutlined />}
            disabled={!currentQuestionId}
            onClick={handleOnStart}
            block
          >
            {!isFirstInActivity || isStarted ? t('continue') : t('start')}
          </Button>
        )}
        {showLanguageSelect && (
          <div>
            <StyledSelect
              size="large"
              style={{
                minWidth: 150,
              }}
              value={loadingQuestionnaire ? '' : currentTranslationId}
              onChange={handleOnSelectTranslation}
              loading={loadingQuestionnaire}
            >
              {map(translations, translation => (
                <SelectOption
                  key={translation.id}
                  value={translation.id}
                  data-language={translation.language}
                >
                  <MessageOutlined />
                  &nbsp;
                  {translation.languageNativeName}
                </SelectOption>
              ))}
            </StyledSelect>
          </div>
        )}
      </Cluster>
      {showLanguageSelect && (
        <Spacer>
          <Text.Paragraph size="small">
            {t('languageNotAvailable')}
          </Text.Paragraph>
        </Spacer>
      )}
    </Stack>
  );
};

AnswersSheetButtons.propTypes = {
  currentProjectLanguage: PropTypes.string,
  answersSheetId: PropTypes.string.isRequired,
};

AnswersSheetButtons.defaultProps = {
  currentProjectLanguage: null,
};

export default AnswersSheetButtons;
