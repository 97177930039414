/* eslint-disable react/jsx-props-no-spreading */
import styled, {
  css,
} from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import {
  theme,
} from '../../../utilsClient/cssHelpers';

const styles = css`
  > * {
    margin: 0;


    ${props => props.resetLineHeight && `
      line-height: 1;
    `};
  }

  > * + * {
    margin-top: ${props => theme(`space.${props.space}`)};
  }
`;

const StackDefault = styled.div`
  ${styles};
`;

const StackList = styled.ul`
  ${styles};
`;

const Stack = ({
  space,
  asList,
  children,
  // eslint-disable-next-line react/prop-types
  className,
  'data-testid': datatestid,
  resetLineHeight,
}) => {
  const props = {
    space,
    className,
    'data-testid': datatestid,
    resetLineHeight,
  };

  return asList ? (
    <StackList {...props}>{children}</StackList>
  ) : (
    <StackDefault {...props}>{children}</StackDefault>
  );
};

Stack.propTypes = {
  children: PropTypes.node.isRequired,
  space: PropTypes.oneOf([
    0,
    1,
    2,
    3,
    4,
    5,
  ]),
  asList: PropTypes.bool,
  resetLineHeight: PropTypes.bool,
  'data-testid': PropTypes.string,
};

Stack.defaultProps = {
  space: 3,
  asList: false,
  resetLineHeight: false,
  'data-testid': 'default-stack',
};

export default Stack;
